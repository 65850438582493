<template>
  <div class="">
    <modal :show="showModal" modal-classes="modal-secondary" size="lg">
      <h3 class="text-center">
        {{ $t("PROPERTY_TRANSACTIONS.PAYMENT_OPERATION") }}
      </h3>
      <form class="add-form pb-2" @submit.prevent="">
        <div class="form-wrapper">
          <base-input
            :label="`${$t('COMMON.TO_THE_ATTENTION_OF')} (*)`"
            :placeholder="$t('COMMON.FIRSTNAME_LASTNAME')"
            v-model="customer"
            type="text"
          >
          </base-input>
          <validation-error :errors="apiValidationErrors.customer" />
        </div>

        <div class="form-wrapper">
          <base-input
            :label="`${$t('COMMON.AMOUNT')} (*)`"
            :placeholder="$t('COMMON.AMOUNT')"
            v-model="transaction.amount"
            type="number"
          >
          </base-input>
          <validation-error :errors="apiValidationErrors.amount" />
        </div>

        <div class="form-wrapper">
          <base-input
            :label="`${$t('PROPERTY_TRANSACTIONS.TRANSACTION_METHODE')} (*)`"
            :placeholder="$t('PROPERTY_TRANSACTIONS.TRANSACTION_METHODE')"
          >
            <el-select
              :clearable="true"
              class="select-primary pagination-select"
              :placeholder="$t('PROPERTY_TRANSACTIONS.TRANSACTION_METHODE')"
              v-model="transaction.method_payment"
            >
              <el-option
                class="select-primary"
                v-for="(item, key) in PAYMENT_METHODS"
                :key="key"
                :label="$t(`AUCTIONS.PAYMENT_SOURCE_${item}`)"
                :value="item"
              >
              </el-option>
            </el-select>
          </base-input>
          <validation-error :errors="apiValidationErrors.method_payment" />
        </div>

        <div class="form-wrapper">
          <base-input
            :label="`${$t('PROPERTY_TRANSACTIONS.TRANSACTION_TYPE')} (*)`"
            :placeholder="$t('PROPERTY_TRANSACTIONS.TRANSACTION_TYPE')"
          >
            <el-select
              :clearable="true"
              class="select-primary pagination-select"
              :placeholder="$t('PROPERTY_TRANSACTIONS.TRANSACTION_TYPE')"
              v-model="transaction.transaction_type"
            >
              <el-option
                class="select-primary"
                v-for="(item, key) in TRANSACTION_TYPES"
                :key="key"
                :label="$t(`PROPERTY_TRANSACTIONS.TRANSACTION_TYPE_${item}`)"
                :value="item"
                v-if="item !== TRANSACTION_TYPE_REFUND"
              >
              </el-option>
            </el-select>
          </base-input>
          <validation-error :errors="apiValidationErrors.transaction_type" />
        </div>
        <div class="form-wrapper full">
          <base-input
            :label="`${$t('COMMON.NOTE')}`"
            :placeholder="$t('COMMON.NOTE')"
          >
            <textarea
              v-model="transaction_excerpt"
              :placeholder="$t('COMMON.NOTE')"
              class="form-control"
              rows="3"
            ></textarea>
          </base-input>
          <validation-error :errors="apiValidationErrors.transaction_excerpt" />
        </div>
      </form>

      <template slot="footer">
        <button
          type="submit"
          class="btn btn-primary"
          @click="handleSubmit"
          :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          {{ $t("COMMON.SAVE") }}
        </button>

        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="onToggleModal"
          :disabled="loading"
          :transactions="[]"
        >
          {{ $t("COMMON.CLOSE") }}
        </button>
      </template>
    </modal>

    <modal :show="showRefundModal" modal-classes="modal-secondary" size="lg">
      <h3 class="text-center">
        {{ $t("PROPERTY_TRANSACTIONS.REFUND_OPERATION") }}
      </h3>
      <h4 class="text-center mt-2">
        {{ $t("PROPERTY_TRANSACTIONS.REFUND_AMOUNT") }} :
        {{ $formatCurrency(refund?.amount) }}
      </h4>
      <form class="add-form pb-2" @submit.prevent="">
        <div class="form-wrapper full">
          <base-input
            :label="`${$t('PROPERTY_TRANSACTIONS.TRANSACTION_METHODE')} (*)`"
            :placeholder="$t('PROPERTY_TRANSACTIONS.TRANSACTION_METHODE')"
            v-model="refund.method_payment"
            :required="true"
          >
            <el-select
              :required="true"
              :clearable="true"
              class="select-primary pagination-select"
              :placeholder="$t('PROPERTY_TRANSACTIONS.TRANSACTION_METHODE')"
              v-model="refund.method_payment"
            >
              <el-option
                class="select-primary"
                v-for="(item, key) in PAYMENT_METHODS"
                :key="key"
                :label="$t(`AUCTIONS.PAYMENT_SOURCE_${item}`)"
                :value="item"
              >
              </el-option>
            </el-select>
          </base-input>
          <validation-error :errors="apiValidationErrors.method_payment" />
        </div>
      </form>

      <template slot="footer">
        <button
          type="submit"
          class="btn btn-primary"
          @click="saveTransaction"
          :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          {{ $t("COMMON.SAVE") }}
        </button>

        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="onCloseRefundModal"
          :disabled="loading"
        >
          {{ $t("COMMON.CLOSE") }}
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import { Button, Option, Select } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ValidationError from "@/components/ValidationError.vue";
import "flatpickr/dist/flatpickr.css";
import { mapGetters } from "vuex";
import { AUCTION_PAYMENTS } from "@/constants/auctions";

import {
  TRANSACTION_TYPE_PAYMENT,
  TRANSACTION_TYPE_REFUND,
  TRANSACTION_TYPES,
} from "@/constants/properties";
import defaultPropertyTransaction from "../defaultPropertyTransaction";
import { cloneDeep } from "lodash";

export default {
  name: "property-transaction-form",

  components: {
    ValidationError,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin, requestErrorMixin],

  props: ["property", "showModal", "refreshView", "transactions", "formErrors"],

  data() {
    return {
      lastStep: {},
      transaction: {
        amount: null,
        method_payment: null,
        transaction_date: null,
        transaction_type: TRANSACTION_TYPE_PAYMENT,
      },
      loading: false,
      transaction_excerpt: this.property?.transaction_excerpt ?? null,
      customer: this.property?.customer ?? null,
      refund: {
        amount: null,
        method_payment: null,
        transaction_date: null,
        transaction_type: TRANSACTION_TYPE_REFUND,
      },
      refund_payment_method: "",
      showRefundModal: false,
      TRANSACTION_TYPES,
      TRANSACTION_TYPE_REFUND,
      PAYMENT_METHODS: AUCTION_PAYMENTS,
    };
  },

  computed: {
    ...mapGetters("transactions", ["list"]),
  },

  methods: {
    async handleSubmit() {
      if (!this.transaction.amount) {
        this.$notify({
          type: "danger",
          message: this.$t("PROPERTY_TRANSACTIONS.AMOUNT_IS_REQUIRED"),
        });
        return;
      }
      if (this.transaction.amount < this.property?.total) {
        this.$notify({
          type: "danger",
          message: this.$t("PROPERTY_TRANSACTIONS.AMOUNT_IS_LESS_THAN_X", {
            x: this.$formatCurrency(this.property?.total),
          }),
        });
        return;
      }
      if (this.transaction.amount - this.property?.total) {
        this.showRefundModal = true;
        this.refund = {
          ...this.transaction,
          method_payment: this.refund?.method_payment ?? "",
          amount: this.transaction.amount - this.property?.total,
          transaction_type: TRANSACTION_TYPE_REFUND,
        };
        return;
      }
      await this.saveTransaction();
    },

    onToggleModal() {
      this.transaction = {
        amount: null,
        method_payment: null,
        transaction_date: null,
        transaction_type: TRANSACTION_TYPE_PAYMENT,
      };
      this.refund = {
        amount: null,
        method_payment: null,
        transaction_date: null,
        transaction_type: TRANSACTION_TYPE_REFUND,
      };

      this.transaction_excerpt = null;
      this.customer = null;
      this.$emit("onToggleModal");
    },

    async saveTransaction() {
      this.loading = true;
      try {
        this.property.transactions.push(this.transaction);
        if (this.refund?.amount) {
          this.property.transactions.push(this.refund);
        }

        await this.$store.dispatch("properties/createTransaction", {
          ...this.property,
          customer: !this.customer ? "-" : this.customer,
          transaction_excerpt: this.transaction_excerpt ?? null,
        });
        this.$notify({
          type: "success",
          message: this.$t("PROPERTY_TRANSACTIONS.TRANSACTION_SAVED"),
        });
        this.loading = false;
        this.refreshView();
        this.onResetForm();
      } catch (error) {
        this.onCloseRefundModal();
        if (error?.response?.status === 400) {
          await this.showRequestError(error);
        } else if (error?.response?.status === 403) {
          await this.showRequestError(error);
        } else {
          this.setApiValidation(error.response.data.errors);
          this.loading = false;
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
        }
        this.showRefundModal = false;
        this.loading = false;
        this.property.transactions = [];
      }
    },

    onCloseRefundModal() {
      this.showRefundModal = false;
    },

    onResetForm() {
      this.onToggleModal();
      this.onCloseRefundModal();

      this.transaction = {
        amount: null,
        method_payment: null,
        transaction_date: null,
        transaction_type: TRANSACTION_TYPE_PAYMENT,
      };
      this.refund = {
        amount: null,
        method_payment: null,
        transaction_date: null,
        transaction_type: TRANSACTION_TYPE_REFUND,
      };

      this.transaction_excerpt = null;
      this.customer = null;
    },
  },

  mounted() {},

  watch: {},
};
</script>
